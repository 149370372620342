import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { breakpoints } from "../../styles";
import { app } from "../../pre-build/internal-api";

export default function MuiTheme({ appStyles = {}, children }) {
  const theme = useTheme(appStyles);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

const useTheme = (appStyles) => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        appStyles.bodyFont,
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    breakpoints,
    button: {
      fontFamily: appStyles.Button?.fontFamily || appStyles.bodyFont,
    },

    overrides: {
      MuiInputBase: {
        input: {
          fontFamily:
            appStyles.AddressInput?.fontFamily || appStyles.Input?.fontFamily,
        },
      },
      MuiFab: {
        primary: {
          ...appStyles.Button,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: appStyles.backgroundColor,
          color: appStyles.textColor,
        },
      },
      MuiCard: {
        root: {
          ...(appStyles.cardBackgroundColor && {
            background: appStyles.cardBackgroundColor,
          }),
          ...appStyles.Card,
        },
      },

      MuiCardHeader: {
        root: {
          ...appStyles.CardTitle,
        },
      },
      MuiCardContent: {
        root: {
          ...appStyles.Card,
        },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: appStyles.accentColor,
        },
        colorTextPrimary: {
          color: appStyles.actionColor,
        },
      },
    },

    ...(appStyles.actionColor &&
      appStyles.accentColor && {
        palette: {
          background: {
            paper: appStyles.backgroundColor,
          },
          primary: {
            main: appStyles.actionColor,
            light: appStyles.actionColor,
            dark: appStyles.actionColor,
          },
          secondary: {
            main: appStyles.accentColor,
            light: appStyles.accentColor,
            dark: appStyles.accentColor,
          },
        },
      }),
  });

  theme.typography.h2 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
  };
  return theme;
};
