import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Slider from "react-slick";
import { getParams, navigateTo } from "../../utils/location";
import withTranslation from "../../hocs/withTranslation";
import SignupView from "../SignupView";
import LoginView from "../LoginView";
import ConsentView from "../ConsentView";
import VerifyCodeView from "../VerifyCodeView";
import { LOGIN_TYPES } from "../LoginView";

import {
  openAuthLogin,
  openAuthSignup,
  showVerifyCode,
  closeVerifyCode,
  loadPrivacyConsentDetails,
  sendPrivacyConsent,
  showPrivacyConsent,
  closePrivacyConsent,
} from "../../store/user/actions";

import { getStyles } from "../../store/selectors";

import { closeAuthModal, closeMobileAuthView } from "../../store/app/actions";

import "slick-carousel/slick/slick.css";

const LOGIN_SIGNUP_SLIDE = 0;
const VERIFY_CODE_SLIDE = 1;
const PRIVACY_CONSENT_SLIDE = 2;

class AuthView extends React.Component {
  state = { disableSignupInputs: false };

  componentDidMount() {
    if (this.props.user.showVerifyView) {
      return this.slider.slickGoTo(VERIFY_CODE_SLIDE);
    }

    if (this.props.appStyles.gdprConsentEnabled) {
      this.props.loadPrivacyConsentDetails();
    }
    const { authMode } = this.props.user;
    const { loginType } = this.props.app;
    const params = getParams(this.props.location);
    if (
      (_.includes(this.props.location.pathname, "signup") ||
        params.openSignup ||
        loginType === LOGIN_TYPES.GIFT_CARD) &&
      authMode != "signup"
    ) {
      this.props.openAuthSignup();
    } else if (
      _.includes(this.props.location.pathname, "login") &&
      authMode != "login"
    ) {
      this.props.openAuthLogin();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.app.mobileAuthViewOpen &&
      this.props.app.mobileAuthViewOpen &&
      this.props.appStyles.gdprConsentEnabled
    ) {
      this.props.loadPrivacyConsentDetails();
    }
    if (this.props.user.showPrivacyConsent) {
      return this.slider.slickGoTo(PRIVACY_CONSENT_SLIDE);
    }
    if (this.props.user.showVerifyView) {
      return this.slider.slickGoTo(VERIFY_CODE_SLIDE);
    }
    if (prevProps.user.showVerifyView && !this.props.user.showVerifyView) {
      return this.slider.slickGoTo(LOGIN_SIGNUP_SLIDE);
    }
    if (!this.props.showVerifyView && !this.props.user.showPrivacyConsent) {
      if (
        !(this.props.user.signupState.sent || this.props.user.loginState.sent)
      ) {
        return this.slider.slickGoTo(LOGIN_SIGNUP_SLIDE);
      }
    }
  }

  closeAuthView = () => {
    this.props.closeMobileAuthView();
    this.props.closeAuthModal();
    (_.includes(this.props.location.pathname, "signup") ||
      _.includes(this.props.location.pathname, "login")) &&
      navigateTo("/");
  };

  render() {
    const {
      T,
      app: { isSSR },
      pageContext,
      user,
      location,
      modalMode,
      closeAuthModal,
      openAuthLogin,
      openAuthSignup,
      showVerifyCode,
      closeVerifyCode,
      loadPrivacyConsentDetails,
      sendPrivacyConsent,
      showPrivacyConsent,
      closePrivacyConsent,
      inStoreSignupBranchId,
    } = this.props;

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      touchMove: false,
      swipeToSlide: false,
      swipe: false,
      touchMove: false,
      lazyLoad: true,
      initialSlide: this.props.user.showVerifyView
        ? VERIFY_CODE_SLIDE
        : LOGIN_SIGNUP_SLIDE,
      afterChange: (currentSlideIndex) =>
        this.setState({
          disableSignupInputs: currentSlideIndex !== LOGIN_SIGNUP_SLIDE,
        }),
      // beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    const isInStoreSignup = Boolean(inStoreSignupBranchId);
    return (
      <Slider {...settings} ref={(slider) => (this.slider = slider)}>
        <div>
          {(isSSR && _.includes(this.props.location.pathname, "signup")) ||
          user.authMode === "signup" ||
          isInStoreSignup ? (
            <SignupView
              {...{
                T,
                pageContext,
                location,
                modalMode,
                openAuthLogin,
                showVerifyCode,
                disableSignupInputs: this.state.disableSignupInputs,
                closeAuthView: this.closeAuthView,
                isInStoreSignup,
              }}
            />
          ) : (
            <LoginView
              {...{
                T,
                pageContext,
                location,
                modalMode,
                openAuthSignup,
                showVerifyCode,
                closeAuthView: this.closeAuthView,
              }}
            />
          )}
        </div>
        <VerifyCodeView
          {...{
            T,
            pageContext,
            location,
            modalMode,
            closeAuthModal,
            closeVerifyCode,
            closeAuthView: this.closeAuthView,
            gdprConsentEnabled: this.props.appStyles.gdprConsentEnabled,
            showPrivacyConsent,
            isInStoreSignup,
            inStoreSignupBranchId,
          }}
        />
        {this.props.appStyles.gdprConsentEnabled && (
          <ConsentView
            {...{
              T,
              pageContext,
              location,
              modalMode,
              closeAuthModal,
              showVerifyCode,
              loadPrivacyConsentDetails,
              sendPrivacyConsent,
              closePrivacyConsent,
              closeAuthView: this.closeAuthView,
            }}
          />
        )}
      </Slider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { user, app } = state;

  return { user, app, appStyles: getStyles(state, props) };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    openAuthLogin: () => dispatch(openAuthLogin()),
    openAuthSignup: () => dispatch(openAuthSignup()),
    showVerifyCode: () => dispatch(showVerifyCode()),
    closeVerifyCode: () => dispatch(closeVerifyCode()),
    closeAuthModal: () => dispatch(closeAuthModal()),
    closeMobileAuthView: () => dispatch(closeMobileAuthView()),
    loadPrivacyConsentDetails: () => dispatch(loadPrivacyConsentDetails()),
    sendPrivacyConsent: (approved) => dispatch(sendPrivacyConsent(approved)),
    showPrivacyConsent: () => dispatch(showPrivacyConsent()),
    closePrivacyConsent: () => dispatch(closePrivacyConsent()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation((props) => <AuthView {...props} />));
