import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import AppContainer from "../AppContainer";
import { navigateTo } from "../../utils/location";
import { isNewUser } from "../../utils/user";
import Button from "../Button";
import Card from "../Card";
import styles from "./index.module.scss";
import withTranslation from "../../hocs/withTranslation";
import Loader from "../Loader";
import { signup, setProfileInSignupFlowMode } from "../../store/user/actions";
import { getStyles } from "../../store/selectors";
import RichText from "../rich-text";
import ExternalLink from "../ExternalLink";

class ConsentView extends React.Component {
  state = { chosenOptions: [], optionsLength: 2 };
  componentDidMount() {
    this.props.loadPrivacyConsentDetails();
  }

  onSubmit = () => {
    console.log(this.state);
    if (this.state.chosenOptions.length === this.state.optionsLength) {
      this.props.sendPrivacyConsent(true);
    }
  };

  onDecline = () => {
    this.props.sendPrivacyConsent(false);
    this.props.closeAuthView();
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.user.consentState.sent &&
      this.props.user.consentState.sent
    ) {
      this.nextPageTransition = setTimeout(() => {
        this.props.closeAuthView();
        if (get(this.props.user, "consentState.data.status")) {
          if (
            get(this.props.appStyles, "showProfileOnSignup") &&
            isNewUser(this.props.user)
          ) {
            this.props.setProfileInSignupFlowMode();
            navigateTo("/profile");
          } else {
            if (this.props.loginType) {
              navigateTo(`/${this.props.loginType}`);
            }
          }
        }
      }, 200);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextPageTransition);
  }

  render() {
    const {
      T,
      appStyles,
      user: {
        consentState: { sending, sent },
        privacyConsentDetails,
      },
      modalMode,
    } = this.props;

    const { Signup = {} } = appStyles;
    if (privacyConsentDetails.loading) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Loading Privacy Consent...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (privacyConsentDetails.error || !privacyConsentDetails.data) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <h4>{T("Could not load Privacy Consent")}</h4>
            <p style={{ color: appStyles.accentColor }}>
              {T("We're experiencing network problems...")}
            </p>
            <Button
              appStyles={appStyles}
              onClick={this.props.loadPrivacyConsentDetails}
            >
              {T("Try Again")}
            </Button>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    const {
      data: { heading, subheading, ageConsentText, loyaltyConsentText },
    } = privacyConsentDetails;

    this.state.optionsLength = loyaltyConsentText && ageConsentText ? 2 : 1;
    return (
      <AppContainer.Content appStyles={appStyles} modalMode={modalMode} animate>
        <AppContainer.CenteredColumn>
          <h2 className={styles.Title} style={Signup}>
            {T("Privacy Consent")}
          </h2>
          <p className={styles.Consent}>{heading}</p>
          <p className={styles.Consent}>{subheading}</p>
        </AppContainer.CenteredColumn>
        <Card appStyles={appStyles}>
          <Card.Content classNames={styles.ConsentSection}>
            <Card.Content.CheckboxGroup
              name={T("")}
              checked={this.state.chosenOptions}
              onChange={(chosenOptions) => this.setState({ chosenOptions })}
              wrap={false}
              appStyles={appStyles}
            >
              {loyaltyConsentText && (
                <Card.Content.CheckboxOption
                  iconSize={16}
                  iconInnerSize={16}
                  pointColor={appStyles.actionColor}
                  value={0}
                  vertical
                >
                  <RichText>{loyaltyConsentText}</RichText>
                </Card.Content.CheckboxOption>
              )}
              {ageConsentText && (
                <Card.Content.CheckboxOption
                  iconSize={16}
                  iconInnerSize={16}
                  pointColor={appStyles.actionColor}
                  value={1}
                  vertical
                >
                  <RichText>{ageConsentText}</RichText>
                </Card.Content.CheckboxOption>
              )}
            </Card.Content.CheckboxGroup>
          </Card.Content>
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 16,
          }}
        >
          <Button
            onClick={this.onSubmit}
            appStyles={appStyles}
            centered
            loading={sending}
            completed={sent}
            style={{
              minWidth: 100,
              ...(this.state.chosenOptions.length <
                this.state.optionsLength && {
                backgroundColor: appStyles.accentColor,
              }),
            }}
            disabled
          >
            {this.state.chosenOptions.length == this.state.optionsLength
              ? T("I Agree")
              : this.state.optionsLength > 1
              ? T("Check all consents")
              : T("Check consent")}
          </Button>
          <p>{T("or")}</p>
          <Button
            linkStyle
            appStyles={appStyles}
            style={{ minWidth: 100, marginTop: 16 }}
            centered
            onClick={this.onDecline}
          >
            <small>{T("Use it as Guest without logging in")}</small>
          </Button>
        </div>
      </AppContainer.Content>
    );
  }
}

const ConsentPage = withTranslation(
  ({
    T,
    appStyles,
    pageContext: { business },
    user,
    signup,
    skipPath,
    location,
    openAuthLogin,
    modalMode,
    closeAuthView,
    showVerifyCode,
    loadPrivacyConsentDetails,
    sendPrivacyConsent,
    closePrivacyConsent,
    setProfileInSignupFlowMode,
  }) => (
    <ConsentView
      T={T}
      appStyles={appStyles}
      business={business}
      user={user}
      onSubmit={signup}
      skipPath={skipPath}
      location={location}
      openAuthLogin={openAuthLogin}
      modalMode={modalMode}
      closeAuthView={closeAuthView}
      showVerifyCode={showVerifyCode}
      loadPrivacyConsentDetails={loadPrivacyConsentDetails}
      sendPrivacyConsent={sendPrivacyConsent}
      closePrivacyConsent={closePrivacyConsent}
      setProfileInSignupFlowMode={setProfileInSignupFlowMode}
    />
  ),
);

const mapStateToProps = (state, props) => {
  const { user, header } = state;

  return {
    user,
    skipPath: header.onCloseTarget,
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    signup: (params) => dispatch(signup(params)),
    resetConsent: () => dispatch(resetConsent()),
    resetConsentKeepData: () => dispatch(resetConsentKeepData()),
    setProfileInSignupFlowMode: () => dispatch(setProfileInSignupFlowMode()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(ConsentPage));
