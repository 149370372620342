import React from "react";
import { connect } from "react-redux";
import AppContainer from "../AppContainer";
import { PhoneInput, InputGroup } from "../Inputs";
import { getParams } from "../../utils/location";
import ErrorMessage from "../ErrorMessage";
import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import styles from "./index.module.scss";
import LoginHeader from "./LoginHeader";
import Card from "../Card";
import { USER_DOES_NOT_EXIST } from "../../utils/constants";
import { getStyles } from "../../store/selectors";
import withTranslation from "../../hocs/withTranslation";
import { login, resetLogin } from "../../store/user/actions";

const PHONE_NUMBER = "phoneNumber";

export const LOGIN_TYPES = {
  GIFTS: "gifts",
  SCAN: "scan",
  REORDER: "reorder",
  PREPAID: "prepaid",
  GIFT_CARD: "giftCard",
  DEALS: "deals",
};

class LoginView extends React.Component {
  inputRefs = {};

  componentDidMount() {
    this.props.reset();
  }

  registerInput = (key) => (ref) => {
    this.inputRefs[key] = ref;
  };

  onSubmit = () => {
    this.inputRefs[PHONE_NUMBER].validate((error, phoneNumber) => {
      if (!error) {
        this.inputRefs[PHONE_NUMBER].inputElement.focus();
        this.inputRefs[PHONE_NUMBER].inputElement.blur();
        this.props.onSubmit(phoneNumber);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.user.loginState.sent && this.props.user.loginState.sent) {
      this.nextPageTransition = setTimeout(() => {
        this.props.showVerifyCode();
      }, 200);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextPageTransition);
  }

  wrapLinkHandler = (handler) => (e) => {
    e.preventDefault();
    handler();
  };

  render() {
    const {
      T,
      appStyles,
      pageContext: { business, businessAppConfiguration },
      user: {
        loginState: { sending, sent, error },
      },
      modalMode,
      closeAuthView,
      openAuthSignup,
      loginType,
    } = this.props;
    const { areaCode } = business;
    const { requireLoginForOrder } = businessAppConfiguration;
    if (error && error !== USER_DOES_NOT_EXIST) {
      console.error(error);
    }
    let params = getParams(this.props.location);

    return (
      <AppContainer.Content appStyles={appStyles} modalMode={modalMode} animate>
        <WrapWithCardIf predicate={loginType} appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <LoginHeader type={loginType} appStyles={appStyles} T={T} />
            {requireLoginForOrder && (
              <small>{T("* Ordering requires login")}</small>
            )}
          </AppContainer.CenteredColumn>
          <InputGroup appStyles={appStyles} T={T}>
            <PhoneInput
              refEl={this.registerInput("phoneNumber")}
              placeholder={T("Phone Number")}
              initialValue={params.lastPhoneSent}
              areaCode={areaCode}
              locale={appStyles.locale}
            />
          </InputGroup>
          <ButtonGroup centered appStyles={appStyles}>
            <Button
              onClick={this.onSubmit}
              appStyles={appStyles}
              centered
              loading={sending}
              completed={sent}
            >
              {T("Login")}
            </Button>
          </ButtonGroup>
          {error && (
            <ErrorMessage appStyles={appStyles}>
              {error === "User doesn't exist"
                ? T("User does not exist")
                : error === "User passed try limit"
                ? T("You passed the try limit, please try again in one hour")
                : error ===
                  "Deleted user, please contact the restaurant support to enable registration for this phone number"
                ? T(error)
                : T("We had a connection problem. Please try again")}
            </ErrorMessage>
          )}
          <AppContainer.CenteredColumn>
            <p style={{ display: "flex", alignItems: "center" }}>
              {T("Not a member yet?")}{" "}
              <Button
                onClick={this.wrapLinkHandler(openAuthSignup)}
                appStyles={appStyles}
                centered
                slim
                label={T("Sign up")}
                style={{
                  ...(appStyles.rtl ? { marginRight: 8 } : { marginLeft: 8 }),
                }}
              >
                {T("Sign up")}
              </Button>
            </p>
          </AppContainer.CenteredColumn>
          {!loginType && (
            <AppContainer.CenteredColumn>
              <a
                href="#"
                onClick={this.wrapLinkHandler(closeAuthView)}
                style={{
                  color: appStyles.actionColor,
                  textTransform: "uppercase",
                }}
              >
                <small className={styles.SkipLink}>{T("Skip")}</small>
              </a>
            </AppContainer.CenteredColumn>
          )}
        </WrapWithCardIf>
      </AppContainer.Content>
    );
  }
}

const LoginPage = withTranslation(
  ({
    T,
    appStyles,
    pageContext,
    user,
    login,
    resetLogin,
    skipPath,
    location,
    modalMode,
    closeAuthView,
    openAuthSignup,
    showVerifyCode,
    loginType,
  }) => (
    <LoginView
      appStyles={appStyles}
      location={location}
      T={T}
      pageContext={pageContext}
      user={user}
      reset={resetLogin}
      onSubmit={login}
      searchParams={location.search}
      skipPath={skipPath}
      modalMode={modalMode}
      closeAuthView={closeAuthView}
      openAuthSignup={openAuthSignup}
      showVerifyCode={showVerifyCode}
      loginType={loginType}
    />
  ),
);

const WrapWithCardIf = ({ predicate, children, appStyles }) =>
  predicate ? (
    <Card appStyles={appStyles}>{children}</Card>
  ) : (
    <div>{children}</div>
  );

const mapStateToProps = (state, props) => {
  const { user, app, header } = state;

  return {
    user,
    loginType: app.loginType,
    skipPath: header.onCloseTarget,
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    login: (phoneNumber) => dispatch(login({ phoneNumber })),
    resetLogin: () => dispatch(resetLogin()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
