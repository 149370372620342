import React from "react";
import _ from "lodash";
import classnames from "classnames";
import isAfter from "date-fns/is_after";
import subYears from "date-fns/sub_years";
import format from "date-fns/format";
import getYear from "date-fns/get_year";
import BirthdayIcon from "../icons/Birthday.svg";
import ErrorXIcon from "../icons/ErrorX.svg";
import localeDates from "../../utils/localeDates";
import styles from "./index.module.scss";
import { Select } from "../Inputs";

export default class extends React.Component {
  state = { error: null };

  validator = (birthday) =>
    isAfter(
      subYears(
        new Date(),
        this.props.minimumAllowedAge || MINIMUM_BIRTHDAY_AGE_REQUIRED,
      ),
      new Date(birthday.year, birthday.month - 1, birthday.day),
    );

  validate = (callback = _.noop) => {
    const {
      onValid = _.noop,
      onError = _.noop,
      birthday,
      required,
      minimumAllowedAge,
    } = this.props;
    const { day, month, year } = birthday || {};
    const userHasNotEnteredBirthday = !day && !month && !year;
    const userEnteredBirthdayAndFieldMissing = !day || !month || !year;
    if (!required && userHasNotEnteredBirthday) {
      return this.setState({ error: null }, () => {
        onValid(null);
        callback(null, null);
      });
    }
    if (userEnteredBirthdayAndFieldMissing) {
      return this.setState({ error: ERROR_MESSAGE }, () => {
        onError(ERROR_MESSAGE);
        callback(ERROR_MESSAGE, null);
      });
    }
    if (!this.validator({ year, month, day })) {
      const ageErrorMessage = getAgeErrorMessage(minimumAllowedAge);
      return this.setState({ error: ageErrorMessage }, () => {
        onError(ageErrorMessage);
        callback(ageErrorMessage, null);
      });
    }
    return this.setState({ error: null }, () => {
      const birthday = format(new Date(year, month - 1, day), "YYYY-MM-DD");
      onValid(birthday);
      callback(null, birthday);
    });
  };

  onChange = (changedBirthdayElement) =>
    this.props.onChange &&
    this.props.onChange({ ...this.props.birthday, ...changedBirthdayElement });

  setDay = (day) => this.setState({ day, error: null }, this.onChange({ day }));
  setMonth = (month) =>
    this.setState({ month, error: null }, this.onChange({ month }));
  setYear = (year) =>
    this.setState({ year, error: null }, this.onChange({ year }));

  render() {
    const { appStyles, T, birthday, minimumAllowedAge, required } = this.props;

    const { day, month, year } = birthday || {};
    const days = _.times(31, (day) => ({ label: day + 1, value: day + 1 }));
    const months = _.map(
      _.get(localeDates, `${appStyles.locale || "en-US"}.MONTHS_SHORT`),
      (month, index) => ({
        label: month,
        value: index + 1,
      }),
    );

    const startYear =
      getYear(new Date()) -
      (minimumAllowedAge || MINIMUM_BIRTHDAY_AGE_REQUIRED);

    const years = _.times(startYear - 1899, (index) => ({
      label: startYear - index,
      value: startYear - index,
    }));

    const errorStyle = { background: "#fae7e7" };

    const hasAgeError =
      this.state.error === getAgeErrorMessage(minimumAllowedAge);

    const dayHasErrors = this.state.error && (!day || hasAgeError);
    const monthHasErrors = this.state.error && (!month || hasAgeError);
    const yearHasErrors = this.state.error && (!year || hasAgeError);
    const selectStyles = _.pick(appStyles.Input, [
      "letterSpacing",
      "fontFamily",
      "fontStyle",
      "fontWeight",
      "color",
    ]);

    return (
      <div>
        <div className={styles.InputWithErrorContainer}>
          <div
            className={classnames(
              styles.SelectorOpenDesktop,
              appStyles.rtl && styles.RTL,
              this.state.error && styles.HasError,
            )}
          >
            <BirthdayIcon />
            <div style={{ margin: "0 10px" }}>
              {T(`Birthday${required ? "" : " (optional)"}`)}
            </div>
          </div>
          <div className={styles.SelectorDesktop}>
            {_.flow([appStyles.locale === "en-US" ? _.reverse : _.map])([
              <Select
                key="day"
                rtl={appStyles.rtl}
                backgroundColor={appStyles.inputOnCardBackgroundColor}
                style={{
                  ...selectStyles,

                  ...(appStyles.rtl ? { paddingRight: 6 } : { paddingLeft: 6 }),
                  ...(dayHasErrors
                    ? errorStyle
                    : { background: appStyles.inputOnCardBackgroundColor }),
                }}
                onChange={this.setDay}
                options={days}
                placeholder={T("Day")}
                noCheckmark
                value={day}
              />,
              <Select
                key="month"
                rtl={appStyles.rtl}
                backgroundColor={appStyles.inputOnCardBackgroundColor}
                style={{
                  ...selectStyles,

                  ...(appStyles.rtl ? { paddingRight: 6 } : { paddingLeft: 6 }),
                  ...(monthHasErrors
                    ? errorStyle
                    : { background: appStyles.inputOnCardBackgroundColor }),
                }}
                onChange={this.setMonth}
                options={months}
                placeholder={T("Month")}
                noCheckmark
                value={month}
              />,
            ])}
            <Select
              rtl={appStyles.rtl}
              backgroundColor={appStyles.inputOnCardBackgroundColor}
              style={{
                ...selectStyles,
                ...(appStyles.rtl ? { paddingRight: 6 } : { paddingLeft: 6 }),
                ...(yearHasErrors
                  ? errorStyle
                  : { background: appStyles.inputOnCardBackgroundColor }),
              }}
              onChange={this.setYear}
              options={years}
              placeholder={T("Year")}
              noCheckmark
              value={year}
            />
          </div>
          {this.state.error && (
            <span
              className={classnames(styles.Error, appStyles.rtl && styles.RTL)}
            >
              <ErrorXIcon className="noselect" />
              {T(this.state.error)}
            </span>
          )}
        </div>
      </div>
    );
  }
}

const ERROR_MESSAGE = "Please enter your birthday. Fill out all the fields.";
const MINIMUM_BIRTHDAY_AGE_REQUIRED = 13;
const getAgeErrorMessage = (minimumAllowedAge) =>
  `You must be ${minimumAllowedAge ||
    MINIMUM_BIRTHDAY_AGE_REQUIRED} years old or older to use this app.`;
