import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import AppContainer from "../AppContainer";
import { CodeInput, InputGroup } from "../Inputs";
import { getParams, setParams, navigateTo } from "../../utils/location";
import { isNewUser } from "../../utils/user";
import ErrorMessage from "../ErrorMessage";
import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import styles from "./index.module.scss";
import {
  resetCodeVerification,
  verifyCode,
  setProfileInSignupFlowMode,
  loadPrivacyConsentDetails,
} from "../../store/user/actions";
import { getStyles } from "../../store/selectors";
import withTranslation from "../../hocs/withTranslation";

const CODE = "code";
class VerifyCodeView extends React.Component {
  inputRefs = {};

  componentDidMount() {
    this.props.reset();
  }

  registerInput = (key) => (ref) => {
    this.inputRefs[key] = ref;
  };

  onSubmit = (phoneNumber) => () => {
    const { inStoreSignupBranchId } = this.props;
    this.inputRefs[CODE].validate((codeInputError, code) => {
      if (!codeInputError && code) {
        this.inputRefs[CODE].inputElement.focus();
        this.inputRefs[CODE].inputElement.blur();
        this.props.onSubmit({
          code: code,
          phoneNumber,
          ...(inStoreSignupBranchId && { branchId: inStoreSignupBranchId }),
        });
      }
    });
  };

  fireFBPixelLeadEventsIfNeeded = () => {
    const {
      business: { trackings },
    } = this.props;

    const fbLead = _.find(
      _.flatMap(_.filter(trackings, { type: "facebook" }), "events"),
      { type: "Lead" },
    );
    if (fbLead) {
      ReactPixel.track("Lead");
    }
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.user.verificationCodeState.sent &&
      this.props.user.verificationCodeState.sent
    ) {
      if (
        getParams(this.props.location).from === "signup" ||
        this.props.user.authMode === "signup"
      ) {
        this.fireFBPixelLeadEventsIfNeeded();
      }
      this.nextPageTransition = setTimeout(() => {
        const { user, appStyles, isInStoreSignup } = this.props;
        const userPrivacyConsent = user.privacyConsent;
        const userPrivacyConsentId = _.get(
          userPrivacyConsent,
          "privacyConsentId",
        );
        const presentedPrivacyConsentId = _.get(
          user.privacyConsentDetails,
          "data.id",
        );

        if (
          appStyles.gdprConsentEnabled &&
          !(
            _.get(userPrivacyConsent, "status") === "APPROVED" &&
            presentedPrivacyConsentId &&
            userPrivacyConsentId &&
            presentedPrivacyConsentId === userPrivacyConsentId
          )
        ) {
          this.props.showPrivacyConsent();
        } else {
          if (isInStoreSignup) {
            navigateTo(
              setParams("/store-thanks", {
                backPath: _.get(this.props, "location.pathname"),
              }),
            );
          } else {
            this.props.closeAuthView();
            if (_.get(appStyles, "showProfileOnSignup") && isNewUser(user)) {
              this.props.setProfileInSignupFlowMode();
              navigateTo("/profile");
            } else {
              if (this.props.loginType) {
                navigateTo(`/${this.props.loginType}`);
              }
            }
          }
        }
      }, 200);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextPageTransition);
  }

  errorMessage = (error) => {
    if (error.triesLeft) {
      if (error.triesLeft > -1) {
        return "Please enter the verification code as it was received";
      } else {
        return "You passed the try limit, please try again in a few minutes";
      }
    }
    return "We had a connection problem. Please try again";
  };

  render() {
    const {
      T,
      appStyles,
      user: {
        lastPhoneSent,
        verificationCodeState: { sending, sent, error },
      },
      modalMode,
      closeVerifyCode,
      isInStoreSignup,
    } = this.props;

    const params = getParams(this.props.location);

    const { lastPhoneSent: lastPhoneSentParam, from } = params;

    const phoneNumber = lastPhoneSent || lastPhoneSentParam;

    const resendTarget =
      from === "signup"
        ? setParams("/signup", params)
        : setParams("/login", params);

    const { PageHeader = {} } = appStyles;

    return (
      <AppContainer.Content
        appStyles={appStyles}
        style={isInStoreSignup ? { paddingTop: 0 } : {}}
        modalMode={modalMode}
        animate
      >
        <AppContainer.CenteredColumn>
          {!isInStoreSignup && <h2 style={PageHeader}>{T("Enter Code")}</h2>}
          <p style={isInStoreSignup ? { color: appStyles.accentColor } : null}>
            {T("Please enter the code we sent you via SMS to")} {phoneNumber}
          </p>
        </AppContainer.CenteredColumn>
        <InputGroup appStyles={appStyles} T={T}>
          <CodeInput
            refEl={this.registerInput("code")}
            placeholder={T("Verification Code")}
          />
        </InputGroup>
        <ButtonGroup appStyles={appStyles}>
          <Button
            onClick={this.onSubmit(phoneNumber)}
            appStyles={appStyles}
            centered
            loading={sending}
            completed={sent}
          >
            {T("Verify")}
          </Button>
        </ButtonGroup>
        {error && (
          <ErrorMessage appStyles={appStyles}>
            {T(this.errorMessage(error))}
          </ErrorMessage>
        )}
        <AppContainer.CenteredColumn>
          <p>
            {T("Didn't receive a code?")}
            <br />
            <span
              onClick={closeVerifyCode}
              style={{
                color: appStyles.actionColor,
                ...appStyles.LinkButton,
              }}
              className={styles.Clickable}
            >
              {T("Resend")}
            </span>
            {` ${T("or")} `}
            <span
              onClick={closeVerifyCode}
              style={{
                color: appStyles.actionColor,
                ...appStyles.LinkButton,
              }}
              className={styles.Clickable}
            >
              {T("Change phone number")}
            </span>
          </p>
        </AppContainer.CenteredColumn>
      </AppContainer.Content>
    );
  }
}

const VerifyCodePage = withTranslation(
  ({
    T,
    pageContext: { business },
    user,
    verifyCode,
    resetCodeVerification,
    location,
    setProfileInSignupFlowMode,
    showPrivacyConsent,
    loadPrivacyConsentDetails,
    modalMode,
    closeVerifyCode,
    closeAuthView,
    appStyles,
    app: { loginType },
    isInStoreSignup,
    inStoreSignupBranchId,
  }) => {
    return (
      <VerifyCodeView
        T={T}
        appStyles={appStyles}
        business={business}
        location={location}
        reset={resetCodeVerification}
        onSubmit={verifyCode}
        user={user}
        loadPrivacyConsentDetails={loadPrivacyConsentDetails}
        showPrivacyConsent={showPrivacyConsent}
        setProfileInSignupFlowMode={setProfileInSignupFlowMode}
        modalMode={modalMode}
        closeVerifyCode={closeVerifyCode}
        closeAuthView={closeAuthView}
        loginType={loginType}
        isInStoreSignup={isInStoreSignup}
        inStoreSignupBranchId={inStoreSignupBranchId}
      />
    );
  },
);

const mapStateToProps = (state, props) => {
  const { user, app } = state;

  return {
    user,
    app,
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetCodeVerification: () => dispatch(resetCodeVerification),
    verifyCode: (params) => dispatch(verifyCode(params)),
    setProfileInSignupFlowMode: () => dispatch(setProfileInSignupFlowMode()),
    loadPrivacyConsentDetails: () => dispatch(loadPrivacyConsentDetails()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyCodePage);
